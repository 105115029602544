module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","generateDefaultLanguagePage":true,"languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://colab.cadth.ca/","trailingSlash":"false","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"lowerCaseLng":true,"debug":true},"pages":[{"matchPath":"/404","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
